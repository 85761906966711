// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agencja-interaktywna-realizacje-js": () => import("./../../../src/pages/agencja-interaktywna-realizacje.js" /* webpackChunkName: "component---src-pages-agencja-interaktywna-realizacje-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-identyfikacja-wizualna-js": () => import("./../../../src/pages/identyfikacja-wizualna.js" /* webpackChunkName: "component---src-pages-identyfikacja-wizualna-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-marketing-internetowy-js": () => import("./../../../src/pages/marketing-internetowy.js" /* webpackChunkName: "component---src-pages-marketing-internetowy-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-strony-internetowe-dla-hoteli-pensjonatow-i-apartamentow-js": () => import("./../../../src/pages/strony-internetowe-dla-hoteli-pensjonatow-i-apartamentow.js" /* webpackChunkName: "component---src-pages-strony-internetowe-dla-hoteli-pensjonatow-i-apartamentow-js" */),
  "component---src-pages-tworzenie-sklepow-internetowych-js": () => import("./../../../src/pages/tworzenie-sklepów-internetowych.js" /* webpackChunkName: "component---src-pages-tworzenie-sklepow-internetowych-js" */),
  "component---src-pages-tworzenie-stron-internetowych-js": () => import("./../../../src/pages/tworzenie-stron-internetowych.js" /* webpackChunkName: "component---src-pages-tworzenie-stron-internetowych-js" */),
  "component---src-pages-uslugi-js": () => import("./../../../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */),
  "component---src-templates-projects-template-js": () => import("./../../../src/templates/projects-template.js" /* webpackChunkName: "component---src-templates-projects-template-js" */)
}

